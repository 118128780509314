<template>
  <div id="wip">
    <p>WORK IN PROGRESS (CECI N'EST PAS FINI)</p>
  </div>
</template>

<script>
export default {
  name: 'WorkInProgress',
  components: {
  },
};
</script>

<style scoped>
#wip p {
  font-size: 25px;
  color: red;
  background: black;
  text-align: center;
}
</style>
